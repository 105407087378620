import { render, staticRenderFns } from "./character-row.vue?vue&type=template&id=45f9cb35&scoped=true&"
import script from "./character-row.vue?vue&type=script&lang=ts&"
export * from "./character-row.vue?vue&type=script&lang=ts&"
import style0 from "./character-row.vue?vue&type=style&index=0&id=45f9cb35&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f9cb35",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VImg,VRow})
