














































import { NftModel } from "@/models/nft-model";
import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterStat: () =>
      import("@/components/character/character-stat-summary.vue"),
    CharacterAvatar: () =>
      import("@/components/character/character-avatar.vue"),
    CharacterAttribute: () =>
      import("@/components/character/character-attribute.vue"),
  },
})
export default class CharacterCard extends Vue {
  @Prop({ default: 1 }) index!: number;
  @Prop() character!: NftModel;
  @Prop({ default: false }) smallPadding!: boolean;
  @Prop({ default: true }) cardHover!: boolean;
  @Prop({ default: true }) cardBorder!: boolean;

  //   openCharacterDetail() {
  //     this.$router.push(`/nft/${this.character.ID}`);
  //   }
}
